let tokenForm = "";
if (document.querySelector("head meta[name='csrf-token']")) {
    tokenForm = document.querySelector("head meta[name='csrf-token']").getAttribute("content");
}

let isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
}


let http_requestEl = false;

function makeRequestEl(url = '/a/', method = 'POST', data, callbackF, itemEl) {
    http_requestEl = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_requestEl = new XMLHttpRequest();
        if (http_requestEl.overrideMimeType) {
            http_requestEl.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_requestEl = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_requestEl = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }
    if (!http_requestEl) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }
    http_requestEl.onreadystatechange = function () {
        alertContentsEl(http_requestEl, callbackF, itemEl)
    };
    http_requestEl.open(method, url, true);
    http_requestEl.send(data);
}

function alertContentsEl(http_requestEl, functionName, itemEl) {
    try {
        if (http_requestEl.readyState == 4) {
            if (http_requestEl.status == 200) {

                functionName(http_requestEl.responseText, itemEl);

            } else {
                console.log('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {
    }
}

window.maskInputPostCode = function (input) {
    let regExpMask = IMask(
        input, {
            mask: '00-000'
        });
}

if (document.querySelector(".input--postcode")) {
    let inputDate = document.querySelectorAll(".input--postcode");
    for (let i = 0; i < inputDate.length; i++) {
        maskInputPostCode(inputDate[i]);
    }
}


if (document.getElementById('nip')) {
    var regExpMask2 = IMask(
        document.getElementById('nip'), {
            mask: Number
        });
}
if (document.getElementById('i_nip')) {
    var regExpMask2 = IMask(
        document.getElementById('i_nip'), {
            mask: Number
        });
}


/**
 * Checks if the input string represents a valid date in the format dd-mm-yyyy.
 *
 * @param {string} data - The input date string to be validated.
 * @returns {boolean} - Returns true if the input is a valid date, false otherwise.
 */
function isData(data) {
    // Replace dots with dashes in the input date string
    data = data.replaceAll(".", "-");

    // Split the date string into an array using dashes as separators
    let tData = data.split("-");

    // Extract day, month, and year from the array after converting them to numbers
    let dzien;
    // Check if the first character of the day is '0', remove it; otherwise, subtract 1 from the day
    tData[0][0] == "0" ? dzien = tData[0].slice(1) : dzien = tData[0] - 1;

    let miesiac;
    // Check if the first character of the month is '0', remove it; otherwise, subtract 1 from the month
    tData[1][0] == "0" ? miesiac = (tData[1].slice(1)) - 1 : miesiac = tData[1] - 1;

    // Extract the year from the input string
    let rok = tData[2];

    // Create a new Date object using the extracted day, month, and year
    d = new Date(rok, miesiac, dzien);

    // Check if the extracted values correspond to a valid date
    if (dzien == d.getDate() && miesiac == d.getMonth() && rok == d.getFullYear())
        // If it's a valid date, return true
        return true;
    else
        // If it's not a valid date, return false
        return false;
}


/**
 * Compares the given date with optional minimum and maximum dates.
 *
 * @param {string} data - The date string to be compared in the format yyyy-mm-dd.
 * @param {string|boolean} [minData=true] - Optional. The minimum date for comparison in the format yyyy-mm-dd or "today".
 * @param {string|boolean} [maxData=true] - Optional. The maximum date for comparison in the format yyyy-mm-dd or "today".
 * @returns {boolean} - Returns true if the given date is within the specified range, false otherwise.
 *
 * @example
 * // Example Usage:
 * const inputDate = "2023-10-27";
 * const isWithinRange = compareDate(inputDate, "2023-01-01", "2023-12-31");
 * console.log(isWithinRange); // Output: true (inputDate is within the specified range)
 */
function compareDate(data, minData = true, maxData = true) {
    data = data.replaceAll(".", "-");

    // Convert date format if it's in dd-mm-yyyy format
    if (data[2] === "-") {
        let val = data.split("-");
        data = `${val[2]}-${val[1]}-${val[0]}`;
    }
    const today = new Date();
    let currentDay = String(today.getDate()).padStart(2, '0');
    let currentMonth = String(today.getMonth() + 1).padStart(2, "0");
    let currentYear = today.getFullYear();
    const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    if (minData === "today") {
        minData = currentDate;
    }
    if (maxData === "today") {
        maxData = currentDate;
    }
    return minData <= data && maxData >= data;
}


function addValidClassForm(el, parent) {
    parent.classList.add("is--valid");
    if (parent.classList.contains("is--invalid")) {
        parent.classList.remove("is--invalid");
        el.removeAttribute("aria-describedby");
        if (parent.querySelector(".form__input--error")) {
            parent.querySelector(".form__input--error").remove();
        }
    }
}

/*dodawanie klasy invalid dla rodzica walidowanego elementu*/
function addInvalidClassForm(el, parent, error) {
    if (parent.querySelector(".form__input--error")) {
        parent.querySelector(".form__input--error").remove();
    }
    parent.classList.remove("is--valid");
    parent.classList.add("is--invalid");
    el.setAttribute("aria-describedby", error);
}

/*tworzenie etykiety błędu*/
function createErrorElementForm(id, parent, message, append = 1) {
    let elError = document.createElement("span");
    elError.setAttribute("id", id);
    elError.classList.add("form__input--error");
    elError.innerHTML = message;
    (append == 1) ? parent.append(elError) : parent.after(elError)
}

let checkSelectvalidate = function (select) {
    let error = "error" + select.getAttribute("id");
    const parent = select.parentNode;
    if (document.querySelector("#" + error)) {
        document.querySelector("#" + error).remove();
    }
    if (select.value != "0") {
        addValidClassForm(select, parent);
    } else {
        addInvalidClassForm(select, parent, error)
        createErrorElementForm(error, parent, "Wybierz jedną z opcji", 0);
    }
}

window.checkInputValidate = function (input) {
    const parent = input.parentNode;
    let error = "error" + input.getAttribute("id");

    let otherValidate = true;
    let inputValidationMessage = input.validationMessage;

    function incorretOtherValidation(input) {
        otherValidate = false;
        if (input.hasAttribute("data-info-validate")) {
            inputValidationMessage = input.getAttribute("data-info-validate");
        }
    }

    if (input.classList.contains("input--data")) {
        let minData = true;
        let maxData = true;

        if (input.hasAttribute("data-min-date")) {
            minData = input.getAttribute("data-min-date");
        }
        if (input.hasAttribute("data-max-date")) {
            maxData = input.getAttribute("data-max-date");
        }

        let pattern = /[0-9]{2}[\.][0-9]{2}[\.][0-9]{4}/;
        if (pattern.test(input.value)) {
            if (!compareDate(input.value, minData, maxData)) {
                incorretOtherValidation(input);
            }
            if (!isData(input.value)) {
                otherValidate = false;
                inputValidationMessage = "Nieprawidłowa data";
            }
        } else {
            inputValidationMessage = "Nieprawidłowa data";
        }


    }

    if (input.hasAttribute("data-pattern") && otherValidate) {
        let pattern = new RegExp(input.getAttribute("data-pattern"));
        if (!pattern.test(input.value)) {
            incorretOtherValidation(input)
        }
    }

    if (input.validity.valid && otherValidate) {
        addValidClassForm(input, parent)
    } else {
        if (parent.classList.contains("is--valid")) {
            parent.classList.remove("is--valid");
        }
        if (input.closest("form").classList.contains("afterFirstValidate") || input.classList.contains("focusout")) {
            addInvalidClassForm(input, parent, error);
            createErrorElementForm(error, parent, inputValidationMessage);
        }
    }
}

let checkBoxValidate = function (input) {
    let error = "error" + input.getAttribute("id");
    const parent = input.parentNode;
    if (input.form.classList.contains("afterFirstValidate") || input.classList.contains("focusout")) {
        if (input.validity.valid) {
            addValidClassForm(input, parent)
        } else {
            addInvalidClassForm(input, parent, error);
            createErrorElementForm(error, parent, input.validationMessage)
        }
    }
}

if (document.querySelector('select:required')) {
    let select = document.querySelectorAll("select:required");
    for (let i = 0; i < select.length; ++i) {
        select[i].addEventListener('change', function (e) {
            (this.value == "0") ? this.classList.remove("js-selected") : this.classList.add("js-selected");
            checkSelectvalidate(this);

        });
    }
}

let inputsRequiredAll = document.querySelectorAll('.form__input textarea:required, .form__input input:required');
let inputsAll = document.querySelectorAll('.form__input textarea, .form__input input');
let inputsCheckBoxAll = document.querySelectorAll('.form__input textarea, .form__checkbox input');
let inputsRequiredCheckBoxAll = document.querySelectorAll('.form__checkbox input:required');

console.log("new");
function addStarToRequired(input,parentsClass=".form__input"){
    console.log("new2");
    let span=document.createElement("span");
    span.innerText="*";
    span.setAttribute("aria-hidden", "true");
   let label= input.closest(parentsClass).querySelector("label");
   console.log(label);
   let nowValueLabel=label.innerText;
   if(parentsClass=".form__checkbox"){
       label.innerText="";
       label.appendChild(span);
       label.appendChild(nowValueLabel);
   }else{
       label.appendChild(span);
   }
   if(input.hasAttribute("placeholder")){
       let placeholderNew=input.getAttribute("placeholder")+"*";
       input.setAttribute("placeholder", placeholderNew);
   }
}

if(inputsRequiredAll) {
    for (let i = 0; i <inputsRequiredAll.length; i++) {
        addStarToRequired(inputsRequiredAll[i]);
    }
}
if(inputsRequiredCheckBoxAll) {
    for (let i = 0; i <inputsRequiredCheckBoxAll.length; i++) {
        addStarToRequired(inputsRequiredCheckBoxAll[i], ".form__checkbox");
    }
}


if (inputsAll) {
    for (let i = 0; i < inputsAll.length; ++i) {
        inputsAll[i].addEventListener("input", function () {
            checkInputValidate(this);
            inputsAll[i].addEventListener("focusout", function () {
                if (!this.classList.contains("focusout")) {
                    this.classList.add("focusout")
                }
                checkInputValidate(this);
            });
        });
    }
}

if (inputsCheckBoxAll) {
    for (let i = 0; i < inputsCheckBoxAll.length; ++i) {
        inputsCheckBoxAll[i].addEventListener("input", function () {
            checkBoxValidate(this);
        });
    }
}

if (document.querySelector("#i_haslo_2")) {
    function remoevErrorPassword() {
        if (document.querySelector("#i_haslo_2").value === document.querySelector("#i_haslo_1").value) {
            let input = document.querySelector("#i_haslo_1");
            let parent = input.parentNode;
            let txt = input.getAttribute("data-nts");
            if (parent.classList.contains("is--invalid")) {
                if (txt === parent.querySelector(".form__input--error").innerHTML) {
                    parent.classList.remove("is--invalid");
                    parent.querySelector(".form__input--error").remove();
                    checkInputValidate(input);
                }
            }
        }
    }

    document.querySelector("#i_haslo_2").addEventListener("input", function () {
        remoevErrorPassword();
    });
}


let formValidate = function (form, namefunction) {

    const $form = form;
    const $btnSubmitt = form.querySelector('button[type="submit"]');

    $btnSubmitt.addEventListener('click', function (e) {
            const inputsRequired = form.querySelectorAll(".form__input input:required, .form__input textarea:required, .form__password input");
            const checkBoxRequired = form.querySelectorAll(".form__checkbox input:required");
            const selectRequired = form.querySelectorAll(".form__select select:required");

            e.preventDefault();
            $form.classList.add("afterFirstValidate");

            for (i = 0; i < inputsRequired.length; ++i) {
                checkInputValidate(inputsRequired[i]);

            }
            for (i = 0; i < checkBoxRequired.length; ++i) {
                checkBoxValidate(checkBoxRequired[i]);
            }
            for (i = 0; i < selectRequired.length; ++i) {
                checkSelectvalidate(selectRequired[i]);
            }

            if (form.querySelector("#i_haslo_2") && (form.querySelector("#i_haslo_2").value != form.querySelector("#i_haslo_1").value)) {
                let input = form.querySelector("#i_haslo_1");
                let parent = input.parentNode;
                if (!(parent).querySelector(".form__input--error")) {
                    let error = "error" + input.getAttribute("id");
                    if (form.querySelectorAll(".is--invalid").length == 0) {
                        input.focus();
                    }
                    addInvalidClassForm(input, parent, error);
                    createErrorElementForm(error, parent, input.getAttribute("data-nts"));
                }
            }

            if (form.querySelectorAll(".is--invalid").length == 0) {

                if (namefunction == 1) {
                    if (document.querySelector("#del-pay-question input[data-rodzaj='9999']")) {
                        if (document.querySelector("#del-pay-question input[data-rodzaj='9999']").checked && !document.querySelector("#input-paczkomat").value.length) {
                            document.querySelector("#paczkomat-box button").click();
                        } else {
                                form.submit();
                        }
                    }

                    if (!document.querySelector("#del-pay-question input[data-rodzaj='9999']")) {
                        form.submit();
                    }


                } else {
                    e.preventDefault();
                    namefunction(form);
                }
            } else {

                if (form.querySelector(".is--invalid")) {
                    let firstInvalidEl = form.querySelector(".is--invalid");
                    if (firstInvalidEl.querySelector("input")) {
                        firstInvalidEl.querySelector("input").focus();
                    } else {
                        if (firstInvalidEl.querySelector("textarea")) {
                            firstInvalidEl.querySelector("textarea").focus();
                        } else {
                            if (firstInvalidEl.querySelector("select")) {
                                firstInvalidEl.querySelector("select").focus();
                            }

                        }
                    }
                }
            }
        }
    )
}

if (document.querySelector('.form--validate')) {
    let formToValidate = document.querySelectorAll(".form--validate");
    for (let i = 0; i < formToValidate.length; ++i) {
        formValidate(formToValidate[i], 1);
    }
}

if (document.querySelector('.select-onsubmit')) {
    let selectOS = document.querySelectorAll(".select-onsubmit");
    for (let i = 0; i < selectOS.length; ++i) {
        selectOS[i].addEventListener('change', function (e) {
            this.form.submit();
        });
    }
}


function validate(evt) {
    if (evt.keyCode != 8) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9\s\/+\/-]/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;

            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    }
}

if (document.getElementById('i_telefon')) {
    document.getElementById('i_telefon').addEventListener("keypress", function (e) {
        validate(e);
    });
}
if (document.getElementById('telefon')) {
    document.getElementById('telefon').addEventListener("keypress", function (e) {
        validate(e);
    });
}

// if (document.querySelector('textarea')) {
//     let textarea = document.querySelectorAll('textarea');
//
//     function autosize() {
//         var el = this;
//         setTimeout(function () {
//             el.style.cssText = 'height:auto; padding:12px';
//             el.style.cssText = 'height:' + el.scrollHeight+24 + 'px';
//         }, 0);
//     }
//
//     for (let i = 0; i < textarea.length; i++) {
//         textarea[i].addEventListener('keydown', autosize);
//     }
// }

function removeAttributes(element, ...attrs) {
    attrs.forEach(attr => {
        element.removeAttribute(attr)
    })
}

function setAttributes(el, attrs) {
    for (let key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
}

//checkbox firma czy osoba prywatna
if (document.querySelector('.cart__customer')) {
    let cartCustomerInputs = document.querySelectorAll(".cart__customer input");
    let formCInputs = document.querySelectorAll(".form__c input");

    let companyInfo=document.querySelector(".fieldset--companyInfo");

    for (i = 0; i < cartCustomerInputs.length; ++i) {
        cartCustomerInputs[i].addEventListener("change", function () {
            if (this.getAttribute("id") == "customer-1") {
                slideUp(companyInfo);

                if (document.querySelector(".fieldset--company")) {
                    slideUp(document.querySelector(".fieldset--company"));
                }

                removeAttributes(document.querySelector("#i_nip"), "pattern", "minlength");
                if (document.querySelector('#f-wysylka-nip')) {
                    removeAttributes(document.querySelector("#f-wysylka-nip"), "pattern", "minlength");
                }
                formCInputs.forEach(attr => {
                    attr.removeAttribute("required");
                    if (attr.closest(".is--invalid")) {
                        attr.closest(".is--invalid").classList.remove("is--invalid");
                        attr.removeAttribute("aria-describedby")
                        if (attr.parentNode.querySelector(".form__input--error")) {
                            attr.parentNode.querySelector(".form__input--error").remove();
                        }
                    }
                    // attr.parentNode.style.display = "none";
                })
                // if (document.querySelector(".form__gus")) {
                //     document.querySelector(".form__gus").style.display = "none";
                // }
            } else {
                slideDown(companyInfo);
                if (document.querySelector(".fieldset--company")) {
                    slideDown(document.querySelector(".fieldset--company"));
                }
                formCInputs.forEach(attr => {
                    attr.setAttribute("required", "required");
                    // attr.parentNode.style.display = "block";
                })
                // if (document.querySelector(".form__gus")) {
                //     document.querySelector(".form__gus").style.display = "block";
                // }
                // ;
                setAttributes(document.querySelector("#i_nip"), {"minlength": "9", "pattern": "[0-9]+"})
                if (document.querySelector('#f-wysylka-nip')) {
                    setAttributes(document.querySelector("#f-wysylka-nip"), {"minlength": "9", "pattern": "[0-9]+"})
                }
                if (document.querySelector('#i_regon')) {
                    document.querySelector('#i_regon').removeAttribute("required");
                }
            }
        })
    }
}

if (document.querySelector('.form__password')) {
    let wrapper = document.querySelectorAll(".form__password");
    for (let i = 0; i < wrapper.length; ++i) {
        let btn = wrapper[i].querySelector("button");
        let input = wrapper[i].querySelector("input");
        btn.addEventListener('click', function (e) {
            e.preventDefault();
            if (input.getAttribute("type") == "password") {
                input.setAttribute("type", "text");
                btn.classList.add("show");
            } else {
                input.setAttribute("type", "password");
                btn.classList.remove("show");
            }
        });
    }
}

//generowanie hasla

if (document.querySelector("#generate__btn")) {
    document.querySelector("#generate__btn").addEventListener("click", function (e) {
        e.preventDefault();
        let generujHaslo = new XMLHttpRequest();
        generujHaslo.onreadystatechange = function () {
            if (generujHaslo.readyState === 4) {
                document.querySelector(".generate__score").classList.add("open")
                document.querySelector(".generate__password").innerHTML = generujHaslo.responseText;
                // document.querySelector("#input-accept").setAttribute("data-password", generujHaslo.responseText);
                let password = generujHaslo.responseText;
                let inputs = document.querySelectorAll("[data-password]");
                for (let i = 0; i < inputs.length; ++i) {
                    inputs[i].value = password;
                    if (inputs[i].parentNode) {
                        addValidClassForm(inputs[i], inputs[i].parentNode)
                    }
                }
            }
        };
        generujHaslo.open('GET', '/a/?a=generuj-haslo');
        generujHaslo.send();
    })
}

// if (document.querySelector("#input-accept")) {
//     document.querySelector("#input-accept").addEventListener("click", function (e) {
//         e.preventDefault();
//         let password = this.getAttribute('data-password');
//         let inputs = document.querySelectorAll("[data-password]");
//         for (let i = 0; i < inputs.length; ++i) {
//             inputs[i].value = password;
//             if (inputs[i].parentNode) {
//                 addValidClassForm(inputs[i], inputs[i].parentNode)
//             }
//         }
//     })
// }


function removeNipError() {
    if (document.getElementById("nip-false")) {
        document.getElementById("nip-false").remove();
    }
}

function fillDataFromGus(data, el) {

    let dataJ = JSON.parse(data);


    if (dataJ.status) {

        document.getElementById("i_firma").value = dataJ.Nazwa;
        document.getElementById("i_ulica").value = dataJ.Ulica;
        document.getElementById("i_numer_budynku").value = dataJ.NumerBudynku;
        document.getElementById("i_numer_mieszkania").value = dataJ.NumerMieszkania;
        document.getElementById("i_kod_pocztowy").value = dataJ.KodPocztowy;
        document.getElementById("i_miasto").value = dataJ.Miejscowosc;
        let inputsInvoice = document.querySelectorAll(".invoice__input input");
        for (let i = 0; i < inputsInvoice.length; ++i) {
            checkInputValidate(inputsInvoice[i]);
        }
    } else {
        removeNipError();

        let box = document.createElement("span");
        box.style = 'display:inline-block; padding:3px 8px; margin-left:10px; font-size:12px; background:#d00035; color:#fff; border-radius:3px;';
        box.id = ("nip-false");
        box.innerText = "Nieprawidłowy numer NIP"
        el.parentNode.appendChild(box);
        setTimeout(function () {
            removeNipError();
        }, 3000)
    }
}

function getDataFromGus() {
    const btnGUS = document.getElementById("btnGus");

    btnGUS.addEventListener("click", function (e) {
        const ownNip = document.getElementById("i_nip").value;
        e.preventDefault();
        dataForm = new FormData();
        dataForm.set('akcja', "gus");
        dataForm.set('nip', ownNip);
        dataForm.set('__csrf__token__', tokenForm);
        makeRequestEl('/a/', "POST", dataForm, fillDataFromGus, btnGUS);
    })
}

if (document.getElementById("btnGus")) {
    getDataFromGus();
}

if (!isMobile) {

    (function () {

        let x, i, j, l, ll, selElmnt, a, b, c;
        /* Look for any elements with the class "custom-select": */
        x = document.getElementsByClassName("custom-select");

        l = x.length;
        for (let i = 0; i < l; i++) {
            let select2 = 0;
            if (x[i].classList.contains("custom-select-2")) {
                select2 = 1;
            }
            selElmnt = x[i].getElementsByTagName("select")[0];
            ll = selElmnt.length;
            /* For each element, create a new DIV that will act as the selected item: */
            a = document.createElement("DIV");


            a.setAttribute("class", "select-selected");
            a.setAttribute("tabindex", "0");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

            x[i].appendChild(a);
            /* For each element, create a new DIV that will contain the option list: */


            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");

            if (select2 == 1) {
                aS = document.createElement("input");
                aS.setAttribute("class", "select-search");
                aS.setAttribute("type", "text");
                aS.setAttribute("placeholder", "znajdź...");
                b.appendChild(aS);
            }

            for (let j = 0; j < ll; j++) {
                /* For each option in the original select element,
                create a new DIV that will act as an option item: */
                c = document.createElement("DIV");
                if (j == 0) {
                    c.classList.add("same-as-selected");
                }
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function (e) {
                    /* When an item is clicked, update the original select box,
                    and the selected item: */
                    let y, i, k, s, h, sl, yl;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    sl = s.length;
                    h = this.parentNode.previousSibling;
                    for (let i = 0; i < sl; i++) {
                        if (s.options[i].innerHTML == this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            yl = y.length;
                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class");
                            }
                            this.setAttribute("class", "same-as-selected");
                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
            aS.addEventListener("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
            })
            if (select2 == 1) {
                aS.addEventListener("keyup", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    filterSearch(aS, b);
                })
            }

            a.addEventListener("click", function (e) {
                /* When the select box is clicked, close any other select boxes,
                and open/close the current select box: */
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
                if (select2 == 1) {
                    aS.focus();
                }
            });
        }

        function closeAllSelect(elmnt) {
            /* A function that will close all select boxes in the document,
            except the current select box: */
            var x, y, i, xl, yl, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            xl = x.length;
            yl = y.length;
            for (i = 0; i < yl; i++) {
                if (elmnt == y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }


            }
            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }

        /* If the user clicks anywhere outside the select box,
        then close all select boxes: */
        document.addEventListener("click", closeAllSelect);
    }());
}

if (document.getElementById("bilecik")) {
    let bilecikInput = document.getElementById("bilecik");
    let box = document.getElementById("bilecik-box");
    let bilecikTextarea = document.getElementById("bilecik-txt");

    bilecikTextarea.addEventListener("input", function () {
        if (this.classList.contains("is--invalid")) {
            if (bilecikTextarea.value > 0) {
                this.classList.remove("is--invalid")
            }
        }
    })

    bilecikInput.addEventListener("change", function () {
        if (bilecikInput.checked) {
            slideDown(box);
        } else {
            slideUp(box);
            if (this.classList.contains("is--invalid")) {
                this.classList.remove("is--invalid");
                bilecikTextarea.value = "";
            }
        }
    })

}